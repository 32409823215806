/* eslint-disable */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { splitCourseSectionTopicId, getUsernameFromAuthData } from "../utils";
import {
  sendInvite,
  reportProblem as reportProblemAPI,
} from "@nualang/nualang-api-and-queries/APIs/Notifications";
import {
  getUserIgnoreCache,
  updateUser,
} from "@nualang/nualang-api-and-queries/APIs/Users";
import { deleteSubscription } from "@nualang/nualang-api-and-queries/APIs/Payments";
import { getGroups } from "@nualang/nualang-api-and-queries/APIs/Groups";
import {
  createGroupMember,
  deleteGroupMember,
} from "@nualang/nualang-api-and-queries/APIs/GroupMembers";
import {
  DataType,
  invalidateCache,
} from "@nualang/nualang-api-and-queries/APIs/invalidation";
import { AppContext } from "./AppContext";
import { users as userQuerys } from "@nualang/nualang-api-and-queries/Queries";
import { useQueryClient } from "@tanstack/react-query";
import config from "../config";

const initialSubscription = {
  plan: "basic",
  isUpgradePossible: false,
  isPaidUser: false,
  stripeCustomerId: null,
  subscriptionId: null,
  subscriptionData: {
    plan: {},
  },
  isSubscriptionLoaded: false,
  paymentMethods: {
    object: "list",
    data: [],
    has_more: false,
    url: "/v1/payment_methods",
  },
};

export const AppContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState(false);
  const [type, setType] = useState("info");
  const [loading, setLoading] = useState(false);
  const [bottomNavigationValue, setBottomNavigationValue] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [user, setCurrentUser] = useState({});
  const [shareScreenOpen, setShareScreenOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [creatorSubscription, setCreatorSubscription] =
    useState(initialSubscription);
  const [isModerateDialogOpen, setIsModerateDialogOpen] = useState(false);
  const [moderationReason, setModerationReason] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);

  
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      setMobile(true);
    }
  }, []);

  const handleOpenModerateDialog = (moderationLabel) => {
    setModerationReason(moderationLabel);
    setIsModerateDialogOpen(true);
  };
  const handleCloseModerateDialog = () => {
    setIsModerateDialogOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const openSnackbar = (message, type) => {
    setMessage(message);
    setType(type);
    setIsSnackbarOpen(true);
  };

  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const handleBottomNavChange = (event, value) => {
    setBottomNavigationValue(value);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setIsMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setIsMobileMenuOpen(false);
  };

  const setUser = useCallback((user) => {
    if (
      user &&
      user.signInUserSession &&
      user.signInUserSession.idToken &&
      user.signInUserSession.idToken.payload
    ) {
      user.attributes = {
        ...user.signInUserSession.idToken.payload,
      };
    }
    const username = getUsernameFromAuthData(user);
    // add 'username' to attributes
    user.attributes["username"] = username;
    setCurrentUser({
      ...user,
      username,
    });
  }, []);

  const sendInvites = async (recipients, link, inviteType) => {
    try {
      startLoading();
      const invite = {
        recipients,
        params: {
          link,
          invite_type: inviteType,
        },
      };
      const response = await sendInvite(invite);

      openSnackbar("Invites sent");
      stopLoading();

      return response;
    } catch (error) {
      console.error(error);
      openSnackbar("There was an error sending invites", "error");
      stopLoading();
    }

    return false;
  };

  const handleShareRoleplay = async (roleplay) => {
    const { courseSectionTopicId, roleplayId } = roleplay;
    let url = "";
    if (courseSectionTopicId && courseSectionTopicId.includes("|")) {
      const { courseId, sectionId, topicId } =
        splitCourseSectionTopicId(courseSectionTopicId);
      url = `${config.APP_URL}/courses/${courseId}/${sectionId}/${topicId}/roleplays/${roleplay.roleplayId}`;
      setShareUrl(url);
    } else {
      url = `${config.APP_URL}/roleplays/${roleplayId}`;
      setShareUrl(url);
    }

    if (navigator.share) {
      await navigator.share({
        title: roleplay.roleplayName,
        text: roleplay.roleplayName,
        url,
      });
    } else {
      setShareScreenOpen(true);
    }
  };

  const handleShareBot = async (bot) => {
    const { courseSectionTopicId, botId } = bot;
    let url = "";
    if (courseSectionTopicId && courseSectionTopicId.includes("|")) {
      const { courseId, sectionId, topicId } = splitCourseSectionTopicId(
        bot.courseSectionTopicId,
      );
      url = `${config.APP_URL}/courses/${courseId}/${sectionId}/${topicId}/bots/${botId}`;
      setShareUrl(url);
    } else {
      url = `${config.APP_URL}/bots/${botId}`;
      setShareUrl(url);
    }

    if (navigator.share) {
      await navigator.share({
        title: bot.botName,
        text: bot.botName,
        url,
      });
    } else {
      setShareScreenOpen(true);
    }
  };

  const handleCloseShare = () => setShareScreenOpen(false);

  const fetchSubscription = useCallback(async (userId) => {
    try {
      if (userId) {
        const currentUser = await getUserIgnoreCache(userId);
        if (currentUser.errorMessage) {
          throw new Error(response.errorMessage);
        }
        let {
          subscriptionPlan,
          subscriptionStatus,
          subscriptionPeriodEnd,
          stripeCustomerId,
          role,
          verificationStatus,
        } = currentUser;

        let currentPlan = subscriptionPlan || "basic";
        const isWaysideTeacher =
          role === "teach" && userId.toLowerCase().includes("wayside");
        let isUpgradePossible =
          role === "teach" &&
          !isWaysideTeacher &&
          (currentPlan === "basic" ||
            [
              "trialing",
              "trialing_expired",
              "incomplete",
              "incomplete_expired",
              "past_due",
              "canceled",
              "unpaid",
              "active_expired",
            ].includes(subscriptionStatus));
        let isPaidUser =
          isWaysideTeacher ||
          (role === "teach" &&
            ["teach", "teacher_pro"].includes(currentPlan) &&
            DateTime.fromSeconds(subscriptionPeriodEnd) > DateTime.now());
        const groupsResponse = await getGroups({
          memberId: userId,
          limit: config.limits.groups,
        });
        const myGroups = groupsResponse.Items;
        // If no active subscription found check group subscriptions
        if (!isPaidUser && myGroups.length) {
          let i = 0;
          let groupSubscription,
            currentPlanTemp,
            isUpgradePossibleTemp,
            isPaidUserTemp;
          while (!isPaidUser && myGroups[i]) {
            groupSubscription = myGroups[i];
            currentPlanTemp = groupSubscription.subscriptionPlan || "basic";
            isUpgradePossibleTemp = currentPlanTemp === "basic";
            isPaidUserTemp =
              ["teach", "teacher_pro"].includes(currentPlanTemp) &&
              DateTime.fromSeconds(groupSubscription.subscriptionPeriodEnd) >
                DateTime.now();
            if (isPaidUserTemp) {
              subscriptionPlan = groupSubscription.subscriptionPlan;
              subscriptionStatus = groupSubscription.subscriptionStatus;
              subscriptionPeriodEnd = groupSubscription.subscriptionPeriodEnd;
              currentPlan = groupSubscription.subscriptionPlan || "basic";
              isUpgradePossible = role === "teach" && currentPlan === "basic";
              isPaidUser = isPaidUserTemp;
            }
            i++;
          }
        }
        return {
          ...subscription,
          plan: currentPlan,
          isUpgradePossible,
          isPaidUser,
          stripeCustomerId,
          subscriptionStatus,
          subscriptionPeriodEnd,
          isSubscriptionLoaded: true,
          groupSubscriptions: myGroups,
          verificationStatus,
        };
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }, []);

  const subscriptionQuery = userQuerys.useSubscription(
    async (userId) => {
      const response = fetchSubscription(userId);
      return response;
    },
    {
      userId: user?.username,
    },
    {
      enabled: !!user?.username,
    },
  );

  const subscription = useMemo(
    () =>
      subscriptionQuery.isSuccess &&
      subscriptionQuery.data &&
      subscriptionQuery.data.plan
        ? subscriptionQuery.data
        : {},
    [subscriptionQuery.data, subscriptionQuery.isSuccess],
  );

  const fetchCreatorSubscription = useCallback(async (userId) => {
    try {
      if (userId) {
        const creatorUser = await getUserIgnoreCache(userId);
        if (creatorUser.errorMessage) {
          throw new Error(response.errorMessage);
        }
        let {
          subscriptionPlan,
          subscriptionStatus,
          subscriptionPeriodEnd,
          stripeCustomerId,
          role,
          verificationStatus,
        } = creatorUser;

        let currentPlan = subscriptionPlan || "basic";
        let isUpgradePossible = role === "teach" && currentPlan === "basic";
        const isWaysideTeacher =
          role === "teach" && userId.toLowerCase().includes("wayside");
        let isPaidUser =
          isWaysideTeacher ||
          (role === "teach" &&
            ["teach", "teacher_pro"].includes(currentPlan) &&
            DateTime.fromSeconds(subscriptionPeriodEnd) > DateTime.now());
        const groupsResponse = await getGroups({
          memberId: userId,
          limit: config.limits.groups,
        });
        const myGroups = groupsResponse.Items;
        // If no active subscription found check group subscriptions
        if (!isPaidUser && myGroups.length) {
          let i = 0;
          let groupSubscription,
            currentPlanTemp,
            isUpgradePossibleTemp,
            isPaidUserTemp;
          while (!isPaidUser && myGroups[i]) {
            groupSubscription = myGroups[i];
            currentPlanTemp = groupSubscription.subscriptionPlan || "basic";
            isUpgradePossibleTemp =
              role === "teach" && currentPlanTemp === "basic";
            isPaidUserTemp =
              role === "teach" &&
              ["teach", "teacher_pro"].includes(currentPlanTemp) &&
              DateTime.fromSeconds(groupSubscription.subscriptionPeriodEnd) >
                DateTime.now();
            if (isPaidUserTemp) {
              subscriptionPlan = groupSubscription.subscriptionPlan;
              subscriptionStatus = groupSubscription.subscriptionStatus;
              subscriptionPeriodEnd = groupSubscription.subscriptionPeriodEnd;
              currentPlan = groupSubscription.subscriptionPlan || "basic";
              isUpgradePossible = role === "teach" && currentPlan === "basic";
              isPaidUser = isPaidUserTemp;
            }
            i++;
          }
        }
        setCreatorSubscription({
          ...creatorSubscription,
          plan: currentPlan,
          isUpgradePossible,
          isPaidUser,
          stripeCustomerId,
          subscriptionStatus,
          subscriptionPeriodEnd,
          isSubscriptionLoaded: true,
          verificationStatus,
        });
        return {
          ...creatorSubscription,
          plan: currentPlan,
          isUpgradePossible,
          isPaidUser,
          stripeCustomerId,
          subscriptionStatus,
          subscriptionPeriodEnd,
          isSubscriptionLoaded: true,
          verificationStatus,
        };
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }, []);

  const cancelSubscription = async (subscriptionId) => {
    try {
      startLoading();
      const deleteSubscriptionResponse =
        await deleteSubscription(subscriptionId);
      const updateUserResponse = await updateUser(user.username, {
        subscriptionPlan: "basic",
        subscriptionId: "none",
      });
      invalidateCache(DataType.USER, {
        userId: user.username,
      });
      openSnackbar("Subscription Canceled", "success");
      stopLoading();
    } catch (error) {
      openSnackbar("There was an error canceling subscription", "error");
      stopLoading();
    }
  };

  const joinGroup = useCallback(async (groupId, username) => {
    try {
      const response = await createGroupMember(groupId, {});
      await invalidateCache(DataType.GROUP_MEMBER, {
        memberId: username,
        groupId,
      });
      queryClient.invalidateQueries({
        queryKey: userQuerys.userKeys.itemSubscription(username),
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, []);

  const leaveGroup = async (groupId) => {
    const { username } = user;
    try {
      startLoading();
      const response = await deleteGroupMember(groupId, username);
      if (response.errorMessage) {
        throw new Error(response.errorMessage);
      }

      await invalidateCache(DataType.GROUP_MEMBER, {
        memberId: username,
        groupId,
      });
      queryClient.invalidateQueries({
        queryKey: userQuerys.userKeys.itemSubscription(username),
      });
      stopLoading();
      openSnackbar("left_group", "success");
      return true;
    } catch (error) {
      stopLoading();
      openSnackbar("problem", "error");
      return false;
    }
  };

  const reportProblem = async (body) => {
    try {
      startLoading();
      await reportProblemAPI(body);
      stopLoading();
      openSnackbar("problem_reported", "success");
    } catch (error) {
      console.error(error);
      stopLoading();
      openSnackbar("problem", "error");
    }
  };

  return (
    <AppContext.Provider
      value={{
        user,
        isSidebarOpen,
        isMobileMenuOpen,
        isSnackbarOpen,
        message,
        mobile,
        type,
        loading,
        bottomNavigationValue,
        mobileMoreAnchorEl,
        shareScreenOpen,
        shareUrl,
        subscription,
        groupSubscriptions: subscription.groupSubscriptions,
        creatorSubscription,
        isModerateDialogOpen,
        isCollapsed,
        moderationReason,
        toggleSidebar,
        openSnackbar,
        closeSidebar,
        openSidebar,
        closeSnackbar,
        setUser,
        handleBottomNavChange,
        handleMobileMenuClose,
        handleMobileMenuOpen,
        sendInvites,
        startLoading,
        stopLoading,
        handleShareRoleplay,
        handleShareBot,
        handleCloseShare,
        fetchCreatorSubscription,
        cancelSubscription,
        joinGroup,
        leaveGroup,
        handleOpenModerateDialog,
        handleCloseModerateDialog,
        reportProblem,
        setIsCollapsed,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  appContext: PropTypes.object,
  children: PropTypes.node,
};

export default AppContextProvider;
